import { h, Component } from 'preact';
import { Router } from 'preact-router';
import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Profile from '../routes/profile';
import { Temporal, Intl, toTemporalInstant } from '@js-temporal/polyfill';
const _ = require('lodash');


export default class App extends Component {
	constructor() {
		super()
		//this.setEvents = this.setEvents.bind(this)
		this.state = {
			tasks: [],
			timeblocks: []
		}

		fetch('https://graphql.123gtd.com/graphql',
			{
	      		method:'POST',
	      		headers:{
	      			'content-type':'application/json',
	      			'x-api-key': 'da2-qqclfjeblvbofmreuiytide3ey'
	      		},
	      		body:JSON.stringify({query:'{listTasks {items {id, name, size, completedAt, forDay}}}'})
			}).then(response => response.json())
			.then(res => {
				let data = res.data.listTasks.items.map(task => {
					task['forDay'] = Temporal.PlainDate.from(task['forDay'])
					return task
				})
				this.setState({tasks: data})
			})

		fetch('https://graphql.123gtd.com/graphql',
			{
	      		method:'POST',
	      		headers:{
	      			'content-type':'application/json',
	      			'x-api-key': 'da2-qqclfjeblvbofmreuiytide3ey'
	      		},
	      		body:JSON.stringify({query:'{listTimeblocks {items {id, name, category, startDateTime, endDateTime, publicNote, privateNote, color}}}'})
			}).then(response => response.json())
			.then(res => {
				// Parse times in timeblocks.
				let tz = Temporal.Now.timeZone()
				let timeblocks = res.data.listTimeblocks.items.map(timeblock => {
					let newTimeblock = timeblock
					newTimeblock['startDateTime'] = tz.getPlainDateTimeFor(timeblock['startDateTime'])
					try{
						newTimeblock['endDateTime'] = tz.getPlainDateTimeFor(timeblock['endDateTime'])
					} catch (e) {
						newTimeblock['endDateTime'] = null
					}
					return newTimeblock
				})
				this.setState({timeblocks})
			})
		
	}

	updateTimeblock(newTimeblock) {
		let timeblocks = this.state.timeblocks.slice();
		if (newTimeblock.id) {
			let index = _.findIndex(timeblocks, {id: newTimeblock.id});	
			timeblocks.splice(index, 1, newTimeblock);
		}

		this.setState({timeblocks})

		let query = `mutation MyMutation($input: UpdateTimeblockInput!) { updateTimeblock(input: $input ) {id} }`
		let serializedTimeblock = {...newTimeblock} // Create a copy
		let tz = Temporal.Now.timeZone()
		serializedTimeblock['startDateTime'] = tz.getInstantFor(newTimeblock['startDateTime']).toString()
		let endTime = newTimeblock['endDateTime']
		serializedTimeblock['endDateTime'] = endTime ? tz.getInstantFor(endTime).toString() : null
		fetch('https://graphql.123gtd.com/graphql',
			{
	      		method:'POST',
	      		headers:{
	      			'content-type':'application/json',
	      			'x-api-key': 'da2-qqclfjeblvbofmreuiytide3ey'
	      		},
	      		body: JSON.stringify({
	      			query,
	      			variables: {input: serializedTimeblock}
	      		})
			}).then((res) => res.text())
			.then((res) => {console.log(res)})
	}

	createTimeblock(newTimeblock) {
		let serializedTimeblock = {...newTimeblock}
		let tz = Temporal.Now.timeZone()
		serializedTimeblock['startDateTime'] = tz.getInstantFor(newTimeblock['startDateTime']).toString()

		let endTime = null
		if (serializedTimeblock['endDateTime']) {
			endTime = tz.getInstantFor(newTimeblock['startDateTime']).toString()	
		}
		serializedTimeblock['endDateTime'] = endTime
		console.log(serializedTimeblock)
		
		let query = `mutation MyMutation($input: CreateTimeblockInput!) {createTimeblock(input: $input) {id}}`
		fetch('https://graphql.123gtd.com/graphql',
			{
	      		method:'POST',
	      		headers:{
	      			'content-type':'application/json',
	      			'x-api-key': 'da2-qqclfjeblvbofmreuiytide3ey'
	      		},
	      		body: JSON.stringify({
	      			query,
	      			variables: {input: serializedTimeblock}
	      		})
			}).then((res) => res.json())
			.then((res) => {
				console.log('Timeblock successfully created!!')
				let id = res.data.createTimeblock.id
				newTimeblock['id'] = id
				newTimeblock['startDateTime'] = Temporal.PlainDateTime.from(newTimeblock['startDateTime'])
				newTimeblock['endDateTime'] = endTime && Temporal.PlainDateTime.from(newTimeblock['endDateTime'])
				let timeblocks = this.state.timeblocks.slice()
				timeblocks.push(newTimeblock)
				this.setState({timeblocks})
			}).catch (e => {
				console.log('TIMEBLOCK FAILED TO BE CREATED!', e)
			})
	}

	createTask(newTask) {
		let serializedTask = {...newTask}
		serializedTask['forDay'] = newTask['forDay'].toString()
		let query = `mutation MyMutation($input: CreateTaskInput!) {createTask(input: $input) {id}}`
		fetch('https://graphql.123gtd.com/graphql',
			{
	      		method:'POST',
	      		headers:{
	      			'content-type':'application/json',
	      			'x-api-key': 'da2-qqclfjeblvbofmreuiytide3ey'
	      		},
	      		body: JSON.stringify({
	      			query,
	      			variables: {input: serializedTask}
	      		})
			}).then((res) => res.json())
			.then((res) => {
				let id = res.data.createTask.id
				newTask['id'] = id
				let tasks = this.state.tasks.slice()
				tasks.push(newTask)
				
				this.setState({tasks})
			})
	}

	render() {
		return (
			<div id="app">
				<Header />
				<Router>
					<Home path="/" 
						timeblocks={this.state.timeblocks} 
						updateTimeblock={this.updateTimeblock.bind(this)} 
						createTimeblock={this.createTimeblock.bind(this)}
						tasks={this.state.tasks}
						createTask={this.createTask.bind(this)} />
					<Profile path="/profile/" user="me" />
					<Profile path="/profile/:user" />
				</Router>
			</div>
		)
	}
}